<template>
  <div>
    <footer v-bind:class="{ position_bottom: !showMenu }">
      <div class="footer-container content-wrap">
        <p>{{ copyright }}</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  computed: {
    copyright() {
      return this.$t('footer.copyright', {year: new Date().getFullYear()})
    },
    showMenu() {
      return (
          this.$route.name !== "signinPage" &&
          this.$route.name !== "forgotPasswordPage"
      );
    }
  }
};
</script>

