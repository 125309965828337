import request from "@/plugins/axiosRequest";
const sign_in_url = "/admin_auth/sign_in";
const sign_out_url = "/admin_auth/sign_out";
const validate_token_url = "/admin_auth/validate_token";
const update_password_url = "/admin_auth/password";
const update_profile_url = "/admin_auth";

export function loginByEmail(email, password) {
  const data = {
    email,
    password
  };
  return request({
    url: sign_in_url,
    method: "post",
    data
  });
}

export function logout() {
  return request({
    url: sign_out_url,
    method: "DELETE"
  });
}

export function validateToken() {
  return request({
    url: validate_token_url,
    method: "GET"
  });
}

export function updatePassword(
  current_password,
  password,
  password_confirmation
) {
  const data = {
    current_password: current_password,
    password: password,
    password_confirmation: password_confirmation
  };
  return request({
    url: update_password_url,
    method: "PUT",
    data
  });
}
export function updateProfile(data) {
  return request({
    url: update_profile_url,
    method: "PUT",
    data
  });
}
