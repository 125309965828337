import Vue from "vue";
import Vuex from "vuex";
import session from "./session";
import settings from "./settings";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        session,
        settings
    },
    state: {},
    mutations: {},
    actions: {}
});

export default store;
