import {
    loginByEmail,
    validateToken,
    updatePassword,
    updateProfile
} from "@/api/session";
import {getToken} from "@/utils/auth";

const profile = {
    state: {
        profile: {}
    },
    mutations: {
        SET_PROFILE: (state, profile) => {
            state.profile = profile;
        }
    },

    actions: {
        LoginByEmail({commit}, userInfo) {
            const email = userInfo.email.trim();
            return new Promise((resolve, reject) => {
                loginByEmail(email, userInfo.password)
                    .then(response => {
                        const data = response.data;
                        commit("SET_PROFILE", data["data"]);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchProfile({commit}) {
            return new Promise((resolve, reject) => {
                let token = getToken();
                if (token) {
                    validateToken()
                        .then(response => {
                            const data = response.data;
                            if (data && data["success"]) {
                                commit("SET_PROFILE", data["data"]);
                                resolve();
                            } else {
                                reject(response);
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    reject();
                }
            });
        },
        updatePassword(
            {commit},
            {currentPassword, password, passwordConfirmation}
        ) {
            return new Promise((resolve, reject) => {
                updatePassword(currentPassword, password, passwordConfirmation)
                    .then(response => {
                        const data = response.data;
                        commit("SET_PROFILE", data["data"]);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        updateProfile({commit}, data) {
            return new Promise((resolve, reject) => {
                updateProfile(data)
                    .then(response => {
                        const data = response.data;
                        commit("SET_PROFILE", data["data"]);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    getters: {
        currentUser: state => state.profile
    }
};

export default profile;
