import {removeToken} from "@/utils/auth";
import {logout} from "@/api/session";

export default [
    {
        name: "APP_LOGIN_SUCCESS",
        callback: function () {
            this.$router.replace({path: "/dashboard"});
        }
    },
    {
        name: "APP_LOGOUT",
        callback: function () {
            logout()
                .then(() => {
                    removeToken();
                    this.$router.replace({path: "/"});
                })
                .catch(error => {
                    console.log(error);
                    removeToken();
                    this.$router.replace({path: "/"});
                });
        }
    },
    {
        name: "APP_AUTH_FAILED",
        callback: function () {
            removeToken();
            this.$router.replace({path: "/"});
        }
    }
];
