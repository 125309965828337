import Vue from 'vue'
import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import moment from 'moment';
import VueMoment from "vue-moment";

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import VueMq from 'vue-mq'
import VeeValidate from 'vee-validate';
import i18n from "./lang";
import store from "./store/";

import '@/scss/_global.scss';

import router from './router'
import "./filters/";

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VueMoment)
Vue.use(moment)
Vue.use(ElementUI, {locale});
Vue.use(VueMq, {
  breakpoints: {
    md: 768,
    lg: Infinity,
  }
})
Vue.use(VeeValidate);

Vue.config.productionTip = false

new Vue({
  store,
  i18n,
  render: h => h(App),
  router,
}).$mount('#app')
