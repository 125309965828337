<template>
  <div v-if="showMenu" class="main-nav">
    <nav class="top-nav fixed-top">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-lg-8 col-md-8 col-8 d-flex align-items-center">
            <div class="col-logo mr-3">
              <img class="d-none d-lg-block" src="@/assets/images/logo_timerich.png" alt="TimeRich" />
              <img class="d-block d-lg-none" src="@/assets/images/logo_short.png" alt="TimeRich" />
            </div>
            <div class="top-nav-title">
              <a @click="collapseLeftNav">
                <svg
                  class="icon-menu"
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.333 6H2.667a.667.667 0 010-1.333h18.666a.667.667 0 110 1.333zm0 6.667H2.667a.667.667 0 010-1.334h18.666a.667.667 0 110 1.334zM2.667 19.333h18.666a.667.667 0 000-1.333H2.667a.667.667 0 000 1.333z"
                    fill="#56CEFF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.333 6H2.667a.667.667 0 010-1.333h18.666a.667.667 0 110 1.333zm0 6.667H2.667a.667.667 0 010-1.334h18.666a.667.667 0 110 1.334zM2.667 19.333h18.666a.667.667 0 000-1.333H2.667a.667.667 0 000 1.333z"
                    fill="url(#paint0_linear)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1=".006"
                      y1="1.74"
                      x2="5.207"
                      y2="20.868"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset=".055" stop-color="#E2F7FF" />
                      <stop offset="1" stop-color="#56CDFE" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
              <p>{{ title }}</p>
            </div>
            <span v-if="isTesting" class="status_red ml-2">Testing</span>
          </div>
          <div class="col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-end">
            
            <div class="account-dropdown-group">
              <a
                class="account-btn"
                href="javascript:;"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  class="top-nav-avatar"
                  src="https://i.pinimg.com/236x/c4/70/26/c47026a9fce3fc13e7f8c115b5d7676c.jpg"
                  alt=""
                />
                <span>{{ adminName }}</span>
                <img
                  class="icon-arrow-down"
                  src="@/assets/svgs/arrow-down.svg"
                  alt="arrowDown"
                />
              </a>
              <ul
                class="dropdown-menu account-dropdown"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <router-link
                    to="/account-settings"
                    class="d-flex align-items-center"
                  >
                    <img
                      class="icon-arrow-down mr-2"
                      src="@/assets/svgs/user.svg"
                      alt="user"
                    />
                    <p>{{ $t("headerMenu.profile") }}</p>
                  </router-link>
                </li>
                <li>
                  <a @click="logOut" class="d-flex align-items-center">
                    <img
                      class="icon-arrow-down mr-2"
                      src="@/assets/svgs/logout.svg"
                      alt="user"
                    />
                    <p>{{ $t("headerMenu.logout") }}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <nav
      class="left-nav"
      :class="{ open: openLeftNav }"
    >
      <ul class="left-nav-list">
        <router-link
          to="/dashboard"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.3511 8.75998C14.435 8.84317 14.5485 8.88955 14.6667 8.88887C14.8453 8.88813 15.0061 8.78053 15.0749 8.61571C15.1438 8.45089 15.1072 8.26087 14.9822 8.13331L8.31554 1.46664C8.14219 1.29432 7.86223 1.29432 7.68888 1.46664L1.02221 8.13331C0.871196 8.30965 0.881349 8.57251 1.04551 8.73667C1.20968 8.90084 1.47254 8.91099 1.64887 8.75998L7.99999 2.40442L14.3511 8.75998ZM2.66665 9.25775L7.99999 3.90664L13.3333 9.22664V14.6666C13.3333 15.1576 12.9353 15.5555 12.4444 15.5555H9.33332V11.1111H6.66665V15.5555H3.55554C3.06462 15.5555 2.66665 15.1576 2.66665 14.6666V9.25775Z"
              fill="#C1CDD4"
            />
          </svg>

          <p>{{ $t("menu.dashboard") }}</p>
        </router-link>
        <router-link
          to="/users-management"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8578 5.97325C10.8632 6.0754 10.8632 6.17776 10.8578 6.27991C10.9431 6.29343 11.0292 6.30086 11.1156 6.30214H11.2C12.0655 6.25599 12.778 5.60523 12.9022 4.74747C13.0265 3.88971 12.5278 3.06355 11.711 2.77377C10.8942 2.48399 9.98635 2.8112 9.54223 3.55547C10.3593 4.08897 10.8536 4.9974 10.8578 5.97325ZM9.9289 5.97769C9.9289 7.0749 9.03944 7.96436 7.94223 7.96436C6.84502 7.96436 5.95556 7.0749 5.95556 5.97769C5.95556 4.88049 6.84502 3.99102 7.94223 3.99102C9.03944 3.99102 9.9289 4.88049 9.9289 5.97769ZM4.8889 10.2221C5.73468 9.40821 6.87604 8.97478 8.0489 9.02213C9.21965 8.97146 10.3595 9.40555 11.2 10.2221L11.3067 10.3555V13.1599C11.3032 13.345 11.2263 13.5211 11.0929 13.6494C10.9595 13.7778 10.7806 13.8479 10.5956 13.8444H5.4889C5.30383 13.8479 5.12492 13.7778 4.99155 13.6494C4.85819 13.5211 4.78129 13.345 4.77779 13.1599V10.3466L4.8889 10.2221ZM5.07112 6.30658H4.93335V6.32436C4.06386 6.32479 3.31581 5.70944 3.14859 4.85618C2.98136 4.00293 3.44179 3.15073 4.24712 2.82293C5.05245 2.49513 5.97715 2.78353 6.45335 3.51102C5.4817 4.08882 4.94035 5.1837 5.07112 6.30658ZM13.9289 8.27992C13.1725 7.52692 12.1377 7.12137 11.0711 7.15992C10.9093 7.16037 10.7476 7.16928 10.5867 7.18658C10.3832 7.61632 10.0781 7.99004 9.69779 8.27547C10.5458 8.51007 11.314 8.97098 11.92 9.60881L12.0311 9.73325L12.2711 10.0088V12.1021H14.0222V8.40436L13.9289 8.27992ZM4.94668 7.17324H5.33335C5.53312 7.6313 5.84677 8.03063 6.24446 8.33324C5.45014 8.57248 4.72976 9.00991 4.15113 9.60435L4.04002 9.73324L3.79557 10.0088V12.0977H1.98224V8.41769L2.08891 8.29324C2.84528 7.54025 3.88009 7.13469 4.94668 7.17324Z"
              fill="#ADBBC4"
            />
          </svg>

          <p>{{ $t("menu.userManagement") }}</p>
        </router-link>
        <router-link
          to="/game-management"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.947 10.831l-1.073-4.705c-.289-1.267-1.42-2.57-3.144-2.57H4.27a3.25 3.25 0 00-3.144 2.57L.053 10.831c-.29 1.277.648 2.502 1.92 2.502.542 0 1.05-.297 1.328-.776l.826-1.42a.785.785 0 01.675-.395h6.396c.275 0 .533.151.675.394l.826 1.421c.278.479.786.776 1.328.776 1.27 0 2.211-1.224 1.92-2.502zM5.992 7.633h-.535v.552c0 .268-.21.484-.47.484a.476.476 0 01-.468-.484v-.552h-.535a.476.476 0 01-.469-.484c0-.267.21-.484.469-.484h.535v-.553c0-.267.21-.483.469-.483s.469.216.469.483v.553h.535c.259 0 .469.217.469.484s-.21.484-.47.484zm4.016 0a.477.477 0 01-.469-.484c0-.267.21-.484.469-.484s.469.217.469.484-.21.484-.469.484zm1.004 1.036a.477.477 0 01-.469-.484c0-.267.21-.484.47-.484.258 0 .468.217.468.484s-.21.484-.469.484zm0-2.073a.477.477 0 01-.469-.484c0-.267.21-.483.47-.483.258 0 .468.216.468.483s-.21.484-.469.484zm1.004 1.037a.477.477 0 01-.469-.484c0-.267.21-.484.47-.484.258 0 .468.217.468.484s-.21.484-.469.484z"
              fill="#C1CDD4"
            />
          </svg>
          <p>{{ $t("gameManagement") }}</p>
        </router-link>
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.9867 6.54245L14.4755 6.9869C14.5798 7.03135 14.6454 7.13594 14.64 7.24912V8.76467C14.6394 8.88312 14.5621 8.9875 14.4489 9.02245L12.9511 9.4669C12.847 9.82023 12.7055 10.1614 12.5289 10.4847L13.2667 11.8625C13.3228 11.9682 13.3029 12.0983 13.2178 12.1825L12.1555 13.2447C12.0714 13.3299 11.9413 13.3497 11.8355 13.2936L10.4533 12.5558C10.1313 12.7306 9.79162 12.8706 9.43999 12.9736L8.99554 14.4758C8.96059 14.589 8.85621 14.6663 8.73776 14.6669H7.23554C7.11709 14.6663 7.01271 14.589 6.97776 14.4758L6.53332 12.978C6.17866 12.8756 5.83597 12.7355 5.5111 12.5602L4.13776 13.2936C4.032 13.3497 3.90193 13.3299 3.81776 13.2447L2.75554 12.1825C2.67036 12.0983 2.65049 11.9682 2.70665 11.8625L3.43554 10.4669C3.25006 10.139 3.10103 9.7917 2.9911 9.43134L1.5111 8.9869C1.39792 8.95195 1.32054 8.84757 1.31998 8.72912V7.2269C1.32054 7.10845 1.39792 7.00407 1.5111 6.96912L2.98221 6.52468C3.09095 6.15821 3.24001 5.80494 3.42665 5.47134L2.70665 4.13801C2.65049 4.03225 2.67036 3.90217 2.75554 3.81801L3.83554 2.76468C3.91971 2.6795 4.04978 2.65963 4.15554 2.71579L5.48887 3.43579C5.82388 3.24884 6.17866 3.09978 6.54665 2.99134L6.9911 1.52468C7.02605 1.4115 7.13043 1.33412 7.24887 1.33356H8.7511C8.8688 1.33093 8.97475 1.40455 9.01332 1.51579L9.45776 2.9869C9.82271 3.09591 10.1745 3.24497 10.5067 3.43134L11.8622 2.7069C11.968 2.65074 12.098 2.67061 12.1822 2.75579L13.2444 3.82245C13.3296 3.90662 13.3495 4.03669 13.2933 4.14245L12.5644 5.5069C12.7421 5.83583 12.8837 6.18304 12.9867 6.54245ZM5.74162 8.93567C6.11997 9.8491 7.0113 10.4447 7.99999 10.4447C9.35002 10.4447 10.4444 9.35026 10.4444 8.00023C10.4444 7.01154 9.84886 6.12021 8.93544 5.74185C8.02201 5.3635 6.97061 5.57264 6.2715 6.27174C5.5724 6.97085 5.36326 8.02225 5.74162 8.93567Z"
                  fill="#ADBBC4"
                />
              </svg>

              <p>{{ $t("menu.settingManagement") }}</p>
            </template>
            <router-link
              to="/system-settings"
              tag="li"
              class="left-nav-item"
              active-class="active"
              @click.native="closeLeftNav"
            >
              <p>{{ $t("menu.setting.system") }}</p>
            </router-link>
                       <router-link
                           to="/game-settings"
                           tag="li"
                           class="left-nav-item"
                           active-class="active"
                           @click.native="closeLeftNav">
                          <p>{{ $t("menu.setting.game") }}</p>
                       </router-link>
            <router-link
              to="/affiliate-settings"
              tag="li"
              class="left-nav-item"
              active-class="active"
              @click.native="closeLeftNav"
            >
              <p>{{ $t("menu.setting.affiliate") }}</p>
            </router-link>
            <router-link
              to="/withdraw-settings"
              tag="li"
              class="left-nav-item"
              active-class="active"
              @click.native="closeLeftNav"
            >
              <p>{{ $t("menu.setting.widthdraw") }}</p>
            </router-link>
            <router-link
              to="/account-settings"
              tag="li"
              class="left-nav-item"
              active-class="active"
              @click.native="closeLeftNav"
            >
              <p>{{ $t("menu.setting.account") }}</p>
            </router-link>
          </el-collapse-item>
        </el-collapse>
        
        <router-link
          to="/affiliate-management"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.03781 5.46614L5.14564 4.62296C5.24956 4.43417 5.31384 4.22041 5.31384 3.9898C5.31384 3.25896 4.72187 2.66699 3.99072 2.66699C3.25957 2.66699 2.66666 3.25865 2.66666 3.9898C2.66666 4.72095 3.25957 5.31323 3.99072 5.31323C4.24848 5.31323 4.48689 5.23647 4.69035 5.10915L5.58127 5.95139C5.71608 5.77321 5.86805 5.61 6.03781 5.46614Z"
              fill="#ADBBC4"
            />
            <path
              d="M9.92227 5.53497L11.1078 3.53999C11.2245 3.57338 11.3456 3.59741 11.4726 3.59741C12.205 3.59741 12.7969 3.00513 12.7969 2.27398C12.7969 1.54314 12.205 0.951172 11.4726 0.951172C10.7414 0.951172 10.1498 1.54283 10.1498 2.27398C10.1498 2.6369 10.2961 2.96487 10.5327 3.20422L9.3509 5.19358C9.55405 5.28564 9.74565 5.40048 9.92227 5.53497Z"
              fill="#ADBBC4"
            />
            <path
              d="M14.0108 7.20508C13.4416 7.20508 12.9595 7.56675 12.7726 8.07104L11.1256 7.90845C11.1256 7.92094 11.1274 7.93279 11.1274 7.94496C11.1274 8.15966 11.1037 8.36936 11.061 8.57189L12.7074 8.73447C12.8069 9.36607 13.3508 9.85038 14.0108 9.85038C14.7417 9.85038 15.3333 9.25872 15.3333 8.52789C15.3333 7.79736 14.7417 7.20508 14.0108 7.20508Z"
              fill="#ADBBC4"
            />
            <path
              d="M9.46574 12.4078L9.09751 10.7988C8.88968 10.8712 8.67343 10.923 8.44781 10.9489L8.81666 12.5582C8.39382 12.7795 8.10205 13.2166 8.10205 13.7272C8.10205 14.458 8.69371 15.0494 9.42611 15.0494C10.1569 15.0494 10.7486 14.4577 10.7486 13.7272C10.7495 13.0098 10.1779 12.429 9.46574 12.4078Z"
              fill="#ADBBC4"
            />
            <path
              d="M5.14686 8.66699L3.09665 9.35383C2.86011 8.99247 2.45288 8.75281 1.98947 8.75281C1.25832 8.75281 0.666664 9.34478 0.666664 10.0756C0.666664 10.8074 1.25832 11.3994 1.98947 11.3994C2.72 11.3994 3.31322 10.8074 3.31322 10.0756C3.31322 10.045 3.30666 10.0169 3.30417 9.98699L5.36093 9.29828C5.26825 9.09825 5.19461 8.88762 5.14686 8.66699Z"
              fill="#ADBBC4"
            />
            <path
              d="M7.99999 10.6668C9.47275 10.6668 10.6667 9.47292 10.6667 8.00016C10.6667 6.5274 9.47275 5.3335 7.99999 5.3335C6.52724 5.3335 5.33333 6.5274 5.33333 8.00016C5.33333 9.47292 6.52724 10.6668 7.99999 10.6668Z"
              fill="#ADBBC4"
            />
          </svg>

          <p>{{ $t("menu.affiliateManagement") }}</p>
        </router-link>
        <router-link
          to="/payment-management"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.3511 8.75998C14.435 8.84317 14.5485 8.88955 14.6667 8.88887C14.8453 8.88813 15.0061 8.78053 15.0749 8.61571C15.1438 8.45089 15.1072 8.26087 14.9822 8.13331L8.31554 1.46664C8.14219 1.29432 7.86223 1.29432 7.68888 1.46664L1.02221 8.13331C0.871196 8.30965 0.881349 8.57251 1.04551 8.73667C1.20968 8.90084 1.47254 8.91099 1.64887 8.75998L7.99999 2.40442L14.3511 8.75998ZM2.66665 9.25775L7.99999 3.90664L13.3333 9.22664V14.6666C13.3333 15.1576 12.9353 15.5555 12.4444 15.5555H9.33332V11.1111H6.66665V15.5555H3.55554C3.06462 15.5555 2.66665 15.1576 2.66665 14.6666V9.25775Z"
              fill="#C1CDD4"
            />
          </svg>

          <p>{{ $t("menu.paymentManagement") }}</p>
        </router-link>
        <router-link
          to="/withdraw"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.32416 7.68118C6.23723 7.87531 6.03226 8.00138 5.80556 8.00015C5.65563 7.99822 5.51284 7.94042 5.40938 7.83977L1.99998 4.6631L5.40938 1.48642C5.54993 1.35519 5.75531 1.30344 5.94816 1.35068C6.14101 1.39791 6.29204 1.53695 6.34434 1.71542C6.39665 1.8939 6.34229 2.08468 6.20175 2.21592L4.13156 4.14572H14.1086C14.4168 4.14572 14.6667 4.37736 14.6667 4.6631C14.6667 4.94883 14.4168 5.18047 14.1086 5.18047H4.13156L6.20175 7.11545C6.36271 7.26346 6.41109 7.48706 6.32416 7.68118ZM10.4705 7.4832C10.6908 7.28159 11.0456 7.28391 11.2628 7.48838L14.6667 10.6833L11.2573 13.8471C11.1167 13.9785 10.9113 14.0302 10.7185 13.983C10.5256 13.9357 10.3746 13.7965 10.3223 13.6179C10.27 13.4393 10.3243 13.2483 10.4649 13.117L12.5407 11.2011H2.55799C2.24981 11.2011 1.99998 10.9693 1.99998 10.6833C1.99998 10.3973 2.24981 10.1655 2.55799 10.1655H12.5407L10.4649 8.2185C10.2476 8.01402 10.2501 7.68482 10.4705 7.4832Z"
              fill="#ADBBC4"
            />
          </svg>

          <p>Fund management</p>
        </router-link>
        <router-link
          to="/consultation-management"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            enable-background="new 0 0 512 512"
            height="512"
            viewBox="0 0 512 512"
            width="512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <circle cx="136" cy="332" r="60" />
              <path
                d="m31 497c0 8.291 6.709 15 15 15h180c8.291 0 15-6.709 15-15 0-57.891-47.109-105-105-105s-105 47.109-105 105z"
              />
              <circle cx="376" cy="332" r="60" />
              <path
                d="m271 497c0 8.291 6.709 15 15 15h180c8.291 0 15-6.709 15-15 0-57.891-47.109-105-105-105s-105 47.109-105 105z"
              />
              <path
                d="m467 0h-422c-24.814 0-45 20.186-45 45v120c0 24.814 20.186 45 45 45h53.789l25.605 25.605c5.859 5.859 15.352 5.859 21.211 0l25.606-25.605h169.578l25.605 25.605c5.859 5.859 15.351 5.86 21.211 0l25.606-25.605h53.789c24.814 0 45-20.186 45-45v-120c0-24.814-20.186-45-45-45zm-272 150h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-60h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15zm242 60h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-60h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
              />
            </g>
          </svg>

          <p>{{ $t("menu.consultationManagment") }}</p>
        </router-link>
        <router-link
          to="/report"
          tag="li"
          class="left-nav-item"
          active-class="active"
          @click.native="closeLeftNav"
        >
          <svg
            enable-background="new 0 0 512 512"
            height="512"
            viewBox="0 0 512 512"
            width="512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="m442.214 104.982c-28.567-28.533-68.408-68.43-96.214-96.202v96.216z"
              />
              <path
                d="m451 134.981-135 .023v-135.004h-255v490.907l127.527-127.014 44.973 44.973 90.866-90.866h-38.366v-30h90v90h-30v-39.208l-112.5 112.5-45.027-45.027-106.143 105.735h368.67zm-194.999 74.756h-120.001v-30h120.001zm0-74.737h-120.001v-30h120.001z"
              />
            </g>
          </svg>

          <p>{{ $t("menu.report") }}</p>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeNames: ["0"],
      openAccountDropdown: false,
      isTesting: process.env.VUE_APP_TESTNET === "true",
      userId: this.$route.params.userId,
    };
  },
  props: ["openLeftNav"],
  computed: {
    ...mapGetters(["currentUser", "pageTitle"]),
    adminName() {
      if (this.currentUser.id) {
        return this.currentUser.name || this.currentUser.email;
      }
      return "Admin";
    },
    showMenu() {
      return (
        this.$route.name !== "Sign in" && this.$route.name !== "forgot password"
      );
    },
    title() {
      let meta = this.$route.meta;
      if (meta && meta["child"]) {
        return this.pageTitle;
      }
      if (meta && meta["title"]) {
        return this.$t(meta["title"]);
      }
      return this.$route.name;
    },
  },
  methods: {
    collapseLeftNav() {
       this.$emit("collapse", this.openLeftNav);
    
    },
    logOut() {
      window.getApp.$emit("APP_LOGOUT");
    },
    closeLeftNav() {
      if (window.innerWidth <= 992) {
        this.$emit("closeLeftNav", this.openLeftNav);
      }
    },
  },
  created() {
    if (!this.currentUser.id) {
      this.$store
        .dispatch("fetchProfile")
        .then(() => {})
        .catch(() => {
          window.getApp.$emit("APP_LOGOUT");
        });
    }
  },
  mounted() {
    window.addEventListener("resize", this.closeLeftNav);
    this.closeLeftNav();
  },
};
</script>
