import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "./en.json";

Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale
    },
};

const i18n = new VueI18n({
    // set locale
    // options: en | ja
    locale: localStorage.getItem("language") || "en",
    // set locale messages
    messages
});

export default i18n;
