<template>
  <div id="app">
    <nav-menu @closeLeftNav="closeLeftNav" @collapse="updateCollapse" :openLeftNav="openLeftNav"></nav-menu>
    <div
      :class="`content-wrap main-body ${
        openLeftNav ? 'container-padding-left' : ''
      }`"
    >
      <router-view></router-view>
    </div>
    <main-footer></main-footer>
    <div class="nav-backdrop " :class="openLeftNav ? 'nav-opening' : ''" @click="closeLeftNav"></div>
  </div>
</template>

<script>
import NavMenu from "@/components/nav-menu";
import MainFooter from "@/components/main-footer";
import AppEvents from "@/event";

export default {
  name: "App",
  data() {
    return {
      openLeftNav: true,
    };
  },
  components: {
    NavMenu,
    MainFooter,
  },
  computed: {
    showBackdrop() {
      return (
        this.$route.name !== "Sign in" && this.$route.name !== "forgot password"
      );
    },
  },
  methods: {
    updateCollapse() {
      this.openLeftNav = !this.openLeftNav;
    },
    closeLeftNav() {
      this.openLeftNav = false;
    },
  },
  created() {
    AppEvents.forEach((item) => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;
  },
};
</script>
