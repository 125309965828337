import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'Sign in', path: '/', component: () => import('../views/signInPage.vue')
        },
        {
            name: 'forgot password', path: '/forgotPassword', component: () => import('../views/forgotPasswordPage.vue')
        },
        {
            name: 'My Dashboard', path: '/dashboard', component: () => import('../views/dashboardPage.vue')
        },
        {
            name: 'User management', path: '/users-management', component: () => import('../views/userManagementPage.vue'),
        },
        {
            name: 'User details', path: '/users-management/detail/:userId/', component: () => import('../views/userDetailPage.vue'),
            meta: { child: true }
        },
        {
            name: 'Game management', path: '/game-management', component: () => import('../views/gameManagementPage.vue'),
            meta: { title: 'gameManagement' }
        },
        {
            name: 'Game setting', path: '/game-settings', component: () => import('../views/gameSettingPage.vue')
        },
        {
            name: 'System settings', path: '/system-settings', component: () => import('../views/SystemSettingsPage.vue')
        },
        {
            name: 'Affiliate setting', path: '/affiliate-settings', component: () => import('../views/affiliatePage.vue')
        },
        {
            name: 'Affiliate management', path: '/affiliate-management', component: () => import('../views/affiliateManagementPage.vue')
        },
        {
            name: 'Affiliate Payment', path: '/payment-management', component: () => import('../views/paymentManagementPage.vue')
        },
        {
            name: 'Affiliate fee', path: '/payment-management/:affiliateId', component: () => import('../views/paymentDetailPage.vue')
        },
        {
            name: 'Fund management', path: '/withdraw', component: () => import('../views/withdrawPage.vue')
        },
        {
            name: 'Withdraw setting', path: '/withdraw-settings', component: () => import('../views/withdrawSettingPage.vue')
        },
        {
            name: 'Account setting', path: '/account-settings', component: () => import('../views/accountSettingPage.vue')
        },
        {
            name: 'Consultation management', path: '/consultation-management', component: () => import('../views/consultationManagementPage.vue')
        },
        {
            name: 'Report', path: '/report', component: () => import('../views/reportPage.vue')
        },
        {
            name: 'Not Found', path: '*', component: () => import('../views/notFoundPage.vue')
        },
    ]
  })
