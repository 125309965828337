const STOP_PREDICTING_LAPSE = 300

export function formatSatoshis(n, decimals) {
    if (!n) {
        return "-"
    }
    return formatDecimals(n / 100, decimals);
}

export function formatTimeRichCoin(n, decimals) {
    if (!n) {
        return "-"
    }
    return formatDecimals(n / 10 ** 6, decimals);
}

export function pointToSatoshis(n) {
    if (!n) {
        return 0
    }
    return n * 100
}

export function formatDecimals(n, decimals) {
    if (!n) {
        return "-"
    }
    if (typeof decimals === 'undefined') {
        if (n % 100 === 0)
            decimals = 0;
        else
            decimals = 2;
    }
    return n.toFixed(decimals).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

//Calculate the payout based on the time
function growthFunc(ms) {
    console.assert(typeof ms == 'number' && ms >= 0);
    let r = 0.00006;
    return Math.floor(100 * Math.pow(Math.E, r * ms)) / 100;
}

//A better name
function calcGamePayout(ms) {
    let gamePayout = growthFunc(ms);
    console.assert(isFinite(gamePayout));
    return gamePayout;
}

//Returns the current payout and stops when lag, use this time to calc game payout with lag
function getElapsedTimeWithLag(engine) {
    if (engine.gameState === 'IN_PROGRESS') {
        let elapsed;
        if (engine.lag) {
            elapsed = engine.lastGameTick - engine.startTime + STOP_PREDICTING_LAPSE; //+ STOP_PREDICTING_LAPSE because it looks better
        } else {
            elapsed = getElapsedTime(engine.startTime);
        }
        return elapsed;
    } else {
        return 0;
    }
}

//Just calculates the elapsed time
function getElapsedTime(startTime) {
    return Date.now() - startTime;
}

export default {
    formatSatoshis,
    formatDecimals,
    growthFunc,
    calcGamePayout,
    getElapsedTimeWithLag,
    getElapsedTime,
    pointToSatoshis
};

