const app = {
    state: {
        language: localStorage.getItem("language") || "en",
        pageTitle: ""
    },
    mutations: {
        SET_PAGE_TITLE: (state, title) => {
            state.pageTitle = title;
        },
    },
    actions: {
        setLanguage({commit}, language) {
            commit("SET_LANGUAGE", language);
        },
        setPageTitle({commit}, title) {
            commit("SET_PAGE_TITLE", title);
        },
    },
    getters: {
        language: state => state.language,
        pageTitle: state => state.pageTitle,
    }
};

export default app;
