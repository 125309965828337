import Vue from "vue";
import {formatSatoshis, formatDecimals, formatTimeRichCoin} from "@/utils/clib"

Vue.filter("capitalize", function (value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("uppercase", function (value) {
    return value.toUpperCase();
});

Vue.filter("formatBalance", function (value, type) {
    switch (type) {
        case "trc":
            return formatTimeRichCoin(value, 2)
        case "pts":
            return formatSatoshis(value, 2)
        default:
            return formatSatoshis(value, 2);
    }
});

Vue.filter("formatSatoshis", function (value) {
    return formatSatoshis(value);
});

Vue.filter("formatTimeRichCoin", function (value) {
    return formatTimeRichCoin(value, 2);
});

Vue.filter("formatDecimals", function (value, decimals) {
    return formatDecimals(value, decimals);
});

Vue.filter("calculateProfit", function (value) {
    if (value["cash_out"]) {
        let profit = ((value["cash_out"] / 100 - 100) * value["bet"]) / 100;
        return formatSatoshis(profit);
    } else {
        return "-"
    }
});
